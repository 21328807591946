import { QuestionCircleOutlined } from '@ant-design/icons';
import { Popover } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import styles from './index.less';

export const Header = observer((props) => {
  return (
    <div className={styles.headerTitle}>
      <span>
        {props.title}
      </span>
      {props.btnRight}
    </div>
  );
});

export const OrderPanel = observer((props) => {
  const { title, panels, background, countColor, style, orderStaticInfo } = props;

  const getPageNameById = (pageId: number): string => {
    switch (pageId) {
      case 60050: return '选款采购订单';
      case 60051: return '选款分销订单';
      case 60055: return '选款售后单';
      case 60057: return '分销售后单';
      default: return '';
    }
  };

  return (
    <div
      className={styles.orderWrap}
      style={style}
    >
      <div
        className={styles.orderItemTitle}
        style={{ background }}
      >
        {title}
      </div>
      <div
        className={styles.orderItemWrap}
      >
        {
          panels?.map((item) => {
            return (
              <div
                className={styles.orderItem}
                key={item.value}
                onClick={() => {
                  const mallSaleOrderIds = JSON.stringify(orderStaticInfo.mallSaleOrderIds || orderStaticInfo.retailSaleOrderIds);
                  const url = item.messageType === 2 ? `${item.url}?messageType=${item.messageType}&mallSaleOrderIds=${mallSaleOrderIds}` : `${item.url}?messageType=${item.messageType}`;
                  window.top.egenie.openTab(url, item.pageId, getPageNameById(item.pageId));
                }}
              >
                <div style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
                >
                  <span
                    className={styles.count}
                    style={{ color: countColor }}
                  >
                    {item.count?.toLocaleString()}
                  </span>
                  {item.showTip && (
                    <Popover
                      content={item.tipText}
                      trigger="hover"
                    >
                      <QuestionCircleOutlined style={{
                        fontSize: 14,
                        color: '#999BA4',
                        marginLeft: 4,
                      }}
                      />
                    </Popover>
                  )}
                </div>
                <div className={styles.orderItemNameWrap}>
                  <span>
                    {item.label}
                  </span>
                  <span>
                    <i className="icon-sq"/>
                  </span>
                </div>
              </div>
            );
          })
        }
      </div>
    </div>
  );
});

export const NoPermissionTip = () => {
  return (
    <span className={styles.noPermissionTip}>
      暂无权限查看
    </span>
  );
};

export const NoSetTip = (props) => {
  return (
    <div className={styles.noSetWrap}>
      <span className={styles.noPermissionTip}>
        {props.tip || '请先设置自己关注的通知范围，便于精准通知，不过度打扰'}
      </span>
      <div className={styles.setBtn}>
        去设置
      </div>
    </div>
  );
};
