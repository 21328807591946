import { message } from 'antd';
import { request } from 'egenie-common';
import _ from 'lodash';
import { action, observable } from 'mobx';
import { api } from '../../../../../utils';
import type { NewTab, ShortCutEdit, ShortCutList } from '../../interface';

class ShortCutStore {
  constructor({ parent }) {
    this.parent = parent;
  }

  @observable public parent;

  @observable public shortCutData: ShortCutEdit = {
    current: [],
    surplus: [],
  };

  @observable public shortCutEditData: ShortCutEdit = {
    current: [],
    surplus: [],
  };

  @observable public isShowEdit = false;

  // 快捷入口切换弹窗显隐
  public toggleShortcutEdit = action((isShow: boolean) => {
    this.isShowEdit = isShow;
    if (isShow) {
      this.shortCutEditData = this.shortCutData;
      this.queryMenuList();
    }
  });

  // 快捷入口的菜单列表
  public queryMenuList = action(() => {
    request({
      url: api.getAllMenus,
      method: 'POST',
      data: { homePageType: null },
    }).then((res: ShortCutList[]) => {
      if (!res) {
        return;
      }
      const resultData = res.map((item) => {
        const filterMenu = item.menus.filter((child) => {
          const index = _.findIndex(this.shortCutData.current, [
            'id',
            child.id,
          ]);
          index > -1 && this.handleShortCutCurrentData(index, item.id);
          return index === -1;
        });
        return {
          ...item,
          menus: filterMenu,
        };
      });
      this.shortCutData.surplus = resultData;
    });
  });

  // 给已经选择的快捷方式添加父级ID
  public handleShortCutCurrentData(index, id) {
    const data: ShortCutList[] = this.shortCutData.current;
    data[index] = {
      ...data[index],
      parentId: id,
    };
    this.shortCutData.current = data;
  }

  // 快捷入口添加删除操作
  public handleShortCutEdit = action((item, type, parentId, index): void => {
    const { surplus, current } = this.shortCutEditData;
    const isAdd = type === 'ADD';
    if (isAdd) {
      if (current.length === 10) {
        message.destroy();
        message.info('最多添加10个');
        return;
      }

      // 从菜单目录里去除要添加的快捷项
      const chooseMenu = surplus[index];
      const menus = chooseMenu.menus.filter((menu) => menu.id !== item.id);
      const result = surplus;
      result[index] = {
        ...chooseMenu,
        menus,
      };
      this.shortCutEditData = {
        surplus: result,
        current: [
          ...current,
          {
            ...item,
            parentId,
          },
        ],
      };
    } else {
      const index = _.findIndex(surplus, [
        'id',
        item.parentId,
      ]);
      const chooseMenu = surplus[index];
      const menus = [
        ...chooseMenu.menus,
        item,
      ];
      const result = surplus;
      result[index] = {
        ...chooseMenu,
        menus,
      };

      this.shortCutEditData = {
        surplus: result,
        current: current.filter((i) => i.id !== item.id),
      };
    }
  });

  // 快捷入口保存
  public handleShortCutSave = action(() => {
    const { current } = this.shortCutEditData;
    const params = current.map((item) => item.id);
    request({
      method: 'post',
      url: api.saveShortcut,
      data: { ids: params.join(',') },
    }).then(() => {
      this.shortCutData = this.shortCutEditData;
      this.toggleShortcutEdit(false);
    })
      .catch((res) => {
        message.error(res.data.data);
      });
  });

  // 快捷入口跳转
  public handleShortCutOpenTab = (item: NewTab): void => {
    // 销售开单等菜单要携带仓库ID
    const warehouseMenu = sessionStorage.getItem('warehouseMenu');
    const warehouseList = warehouseMenu ? JSON.parse(warehouseMenu) : [];
    const currentWareHouse = sessionStorage.getItem('currentWareHouse');
    const url = warehouseList.includes(Number(item.id)) ? `${item.url}?shopAndWarehouseId=${currentWareHouse}` : item.url;
    window.top.egenie.openTab(url, item.id, item.name, item.icon);
  };
}
export default ShortCutStore;
