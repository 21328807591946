import type { BaseData, PaginationData } from 'egenie-common';
import { request } from 'egenie-common';
import { action, observable } from 'mobx';
import { api } from '../../../../../utils';
import type { NotReadMessageStatistics } from '../../../../notificationDrawer/content/interface';
import { SystemType } from '../../../../components/noticeBadge/constant';

export default class NoticeCenterStore {
  constructor({ parent }) {
    this.parent = parent;
    this.getSettingExist();
    this.getMessageList();
  }

  @observable public parent;

  @observable public messageList = [];

  @observable public allUnreadNum = 0;

  @observable public settingExist = false; // 是否设置通知范围

  @observable public hasRequest = false;

  @action private getSettingExist = () => {
    request({ url: api.settingExist })
      .then((res: BaseData<number>) => {
        this.settingExist = Boolean(res.data);
      });
  };

  @action public getMessageList = () => {
    request<PaginationData>({
      url: api.getBossMessageList,
      method: 'post',
      data: {
        page: 1,
        businessType: 0,
        pageSize: 3,
      },
    })
      .then((res) => {
        this.messageList = res.data.list;
        this.hasRequest = true;
      });
    this.getUnreadListNum();

    // request<BaseData<Array<{ unReadCount: number; }>>>({ url: api.getUnReadList })
    //   .then((res) => {
    //     let unreadNum = 0;
    //     res.data?.map((item) => {
    //       unreadNum += item.unReadCount;
    //     });
    //     this.allUnreadNum = unreadNum;
    //   });
  };

  @action public getUnreadListNum = (): void => {
    request<BaseData<NotReadMessageStatistics>>({
      url: api.getNotReadMessageStatistics,
      data: { systemType: SystemType.BOSS_PC },
      method: 'POST',
    })
      .then((res) => {
        this.allUnreadNum = res.data.total;
      });
  };
}
