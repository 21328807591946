import { observer } from 'mobx-react';
import React from 'react';
import { AFTER_SALES_ORDER_LIST, AFTER_SALES_SALE_ORDER_LIST } from '../constant';
import { Header, OrderPanel } from './base/base';
import styles from './index.less';
import { handleData } from './orderAgent';

export const AfterSalesOrderAgent = observer((props) => {
  const { orderStaticInfo, saleOrderStaticInfo } = props.store;
  const orderStatic = handleData(AFTER_SALES_ORDER_LIST, orderStaticInfo);
  const saleOrderStatic = handleData(AFTER_SALES_SALE_ORDER_LIST, saleOrderStaticInfo);
  return (
    <div className={styles.orderAgentWrap}>
      <Header title="售后单待办"/>
      <OrderPanel
        background="linear-gradient(270deg, #FFFFFF 0%, #9AB3FF 100%)"
        countColor="#1978FF"
        orderStaticInfo={orderStaticInfo}
        panels={orderStatic}
        style={{ marginBottom: 50 }}
        title="选款售后单"
      />
      <OrderPanel
        background="linear-gradient(270deg, #FFFFFF 0%, #FFC49F 100%)"
        countColor="#FD7D47"
        orderStaticInfo={saleOrderStaticInfo}
        panels={saleOrderStatic}
        title="分销售后单"
      />
    </div>
  );
});
