import { Spin } from 'antd';
import React from 'react';
import { AfterSalesOrderAgent } from './components/afterSalesOrderAgent';
import { NoticeCenter } from './components/noticeCenter';
import { OrderAgent } from './components/orderAgent';
import { ShortCut } from './components/shortCut';
import styles from './index.less';
import BossDashBoardStore from './store';

const store = new BossDashBoardStore();

export const BossDashBoard = ((props) => {
  const { pushAllUnreadNum } = props;
  return (
    <div className={styles.dashboard}>
      <Spin
        spinning={store.loading}
        style={{ height: '100%' }}
      >
        <div className={`${styles.panel}`}>
          <OrderAgent store={store}/>
          <NoticeCenter
            pushAllUnreadNum={pushAllUnreadNum}
            store={store.noticeCenterStore}
          />
        </div>
        <div className={`${styles.panel}`}>
          <AfterSalesOrderAgent store={store}/>
          <ShortCut store={store.shortCutStore}/>
        </div>
      </Spin>
    </div>
  );
});
