import { Modal, Tabs } from 'antd';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { Header, NoSetTip } from '../base/base';
import styles from './index.less';

const listPanel = (styles, list, type, parentId?, tabIndex?, callback?: () => void) => {
  return (
    <div className={styles.shortcutList}>
      {list.map((item) => (
        <div
          className={styles.item}
          key={item.id + type}
          onClick={!type ? callback.bind(this, item) : undefined} // 快捷方式跳转对应iframe页面
        >
          <div className={styles.iconWrapper}>
            <span
              className={`${styles.icon} icon-${item.icon}`}
              style={{ color: type === 'ADD' ? '#6D6E78' : '#2387FA' }}
            />
            {type && (
              <span
                className={`${styles.operationIcon} icon-${type === 'ADD' ? 'home_tj' : 'home_sj'}`}
                onClick={callback.bind(this, item, type, parentId, tabIndex)}
              />
            )}
          </div>
          <span className={styles.name}>
            {item.name}
          </span>
        </div>
      ))}
    </div>
  );
};

export const ShortCut = observer((props) => {
  const { shortCutEditData, shortCutData, isShowEdit, toggleShortcutEdit, handleShortCutEdit, handleShortCutSave, handleShortCutOpenTab } = props.store;
  const items = shortCutEditData.surplus.map((item, index) => ({
    label: item.name,
    key: item.id.toString(),
    children: listPanel(styles, item.menus, 'ADD', item.id, index, handleShortCutEdit),
  }));
  return (
    <div
      className={styles.shortcut}
    >
      <div className={styles.content}>
        <div className={styles.header}>
          <Header
            btnRight={(
              <span
                className={`${styles.icon} icon-home_bj`}
                onClick={toggleShortcutEdit.bind(this, true)}
              />
            )}
            title="快捷入口"
          />

        </div>
        {shortCutData.current.length > 0 ? listPanel(styles, shortCutData.current, null, null, null, handleShortCutOpenTab) : null}
        {shortCutData.current.length === 0 ? (
          <div
            className={styles.noContentWrap}
            onClick={toggleShortcutEdit.bind(this, true)}
          >
            <NoSetTip tip="可将自己的常用菜单，设置为快捷入口"/>
          </div>
        ) : null}
      </div>
      {isShowEdit && (
        <Modal
          centered
          closable={false}
          onCancel={toggleShortcutEdit.bind(this, false)}
          onOk={handleShortCutSave}
          open
          title="快捷入口"
          width={600}
          wrapClassName={styles.shortCurtModalWrapper}
        >
          <div className={styles.currentChoose}>
            {listPanel(styles, shortCutEditData.current, 'DELETE', null, null, handleShortCutEdit)}

          </div>
          <div className={styles.reset}>
            <Tabs
              defaultActiveKey={shortCutEditData?.surplus.length > 0 ? shortCutEditData?.surplus[0].id.toString() : ''}
              items={items}
            />

          </div>
        </Modal>
      )}
    </div>
  );
});

