import type { BaseData } from 'egenie-common';
import { request } from 'egenie-common';
import { action, observable } from 'mobx';
import { api } from '../../../utils';
import NoticeCenterStore from './components/noticeCenter/store';
import ShortCutStore from './components/shortCut/store';
import type { IMessageType, IOrderStaticInfo, ISaleOrderStaticInfo } from './interface';

class BossDashBoardStore {
  constructor() {
    this.init();
  }

  @observable public loading = false;

  @observable public orderList = [];

  @observable public shortCutStore = new ShortCutStore({ parent: this });

  @observable public noticeCenterStore = new NoticeCenterStore({ parent: this });

  @observable public orderStaticInfo: Partial<IOrderStaticInfo> = {}; // 选款订单、售后单统计数据

  @observable public saleOrderStaticInfo: Partial<ISaleOrderStaticInfo> = {}; // 分销订单、售后单统计数据

  @observable public messsageType: IMessageType[] = [];

  @action public init = () => {
    this.loading = true;
    Promise.all([
      this.queryCommonInfo(),
      this.getOrderStatisInfo(),
      this.querySaleOrderStatisInfo(),
      this.getMessageType(),
    ]).then(() => {
      this.loading = false;
    });
  };

  public queryCommonInfo = action(() => {
    request({ url: `${api.queryCommonInfo}` }).then((res: BaseData<{ shortcut; }>) => {
      this.shortCutStore.shortCutData.current = res.data.shortcut;
    });
  });

  public getOrderStatisInfo = action(() => {
    request({ url: '/api/mall/operation/rest/mall/saleOrder/index/statistics' }).then((res: BaseData) => {
      Object.assign(this.orderStaticInfo, res.data || {});
    });
  });

  public querySaleOrderStatisInfo = action(() => {
    request({ url: '/api/retail/shop/applet/rest/boss/index/statistics' }).then((res: BaseData) => {
      Object.assign(this.saleOrderStaticInfo, res.data || {});
    });
  });

  public getMessageType = action(() => {
    request({
      url: '/api/boss/baseinfo/rest/message/messageTypes',
      method: 'post',
    }).then((res: BaseData<IMessageType[]>) => {
      this.messsageType = res.data;
    });
  });
}
export default BossDashBoardStore;
