import { Button, Col, Form, Input, message, Modal, Row } from 'antd';
import type { FormInstance } from 'antd/lib/form';
import { request, phoneReg } from 'egenie-common';
import React from 'react';
import { api } from '../../utils';
import { LayoutGuide, LayoutStore } from '../../utils/layout';
import { BossDashBoard } from './bossDashBoard';
import NotificationDrawerStore from '../notificationDrawer/store';
import { SystemType } from '../components/noticeBadge/constant';
import NoticeBadge from '../components/noticeBadge';

const notificationCenterStore = new NotificationDrawerStore({ parent: this });

const layoutStore = new LayoutStore({
  project: {
    name: 'BOSS',
    value: 'egenie-boss',
  },
});
layoutStore.handleInit();
let timer = null;

interface IState {
  isShowPhoneChange: boolean;
  countDown: number;
  isSendCode: boolean;
  unMessageNum: number;
}

interface RightOperation {
  id: string;
  name: string;
  callback?: () => void;
}

// 导航 示例
export default class Index extends React.Component {
  public state: IState = {
    isShowPhoneChange: false,
    countDown: 60,
    isSendCode: false,
    unMessageNum: 0,
  };

  componentDidMount() {
    console.log('componentDidMount');
  }

  public phoneFormRef = React.createRef<FormInstance>();

  public toggleChangePhone = (show: boolean): void => {
    this.setState({ isShowPhoneChange: show });
    if (!show) {
      clearInterval(timer);
      this.setState({
        isSendCode: false,
        countDown: 60,
      });
    }
  };

  public handlePhoneOk = (): void => {
    this.phoneFormRef?.current.validateFields()
      .then((values) => {
        request({
          method: 'post',
          url: api.modifyPhone,
          data: values,
        })
          .then(() => {
            message.success('手机号修改成功！');
            this.toggleChangePhone(false);
          });
      });
  };

  public handlePhoneCode = (): void => {
    this.phoneFormRef?.current.validateFields(['mobile'])
      .then((values) => {
        if (this.state.isSendCode) {
          return null;
        }
        request({
          method: 'post',
          url: api.anonSend,
          data: values,
        })
          .then(() => {
            this.setState({
              isSendCode: true,
              countDown: 60,
            });
            this.handleCountDown();
          });
      });
  };

  // 倒计时
  public handleCountDown = ((): void => {
    clearInterval(timer);
    timer = setInterval(() => {
      this.setState((state: IState) => ({ countDown: state.countDown - 1 }));
      if (this.state.countDown < 1) {
        clearInterval(timer);
        this.setState({ isSendCode: false });
      }
    }, 1000);
  });

  public changePhoneModal = (): JSX.Element => (
    <Modal
      maskClosable={false}
      onCancel={this.toggleChangePhone.bind(this, false)}
      onOk={this.handlePhoneOk}
      open
      title="修改手机号"
    >
      <Form
        labelCol={{ span: 6 }}
        ref={this.phoneFormRef}
        wrapperCol={{ span: 15 }}
      >
        <Form.Item
          label="请输入新手机号"
          name="mobile"
          rules={[
            {
              required: true,
              pattern: phoneReg,
              message: '请输入正确手机号(11位)',
            },
            {
              validateTrigger: 'onBlur',
              validator(_, value) {
                if (!phoneReg.test(value)) {
                  return Promise.resolve('请输入正确手机号(11位)');
                }
                return Promise.resolve();
              },
            },
          ]}
          validateTrigger={['onChange']}
        >
          <Input autoComplete="off"/>
        </Form.Item>
        <Form.Item
          label="短信验证码"
          name="validCode"
          rules={[
            {
              required: true,
              message: '请输入验证码',
            },
          ]}
          validateTrigger={['onChange']}
        >
          <Row>
            <Col>
              <Input autoComplete="off"/>
            </Col>
            <Col style={{ marginLeft: '8px' }}>
              <Button
                onClick={this.handlePhoneCode}
                type="primary"
              >
                {this.state.isSendCode ? `${this.state.countDown}S后重新获取` : '获取验证码'}
              </Button>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </Modal>
  );

  public userInfoLeft = (): JSX.Element => {
    // console.log('userInfoLeft');
    return (
      <div>
        <NoticeBadge

          // callback={(unread) => {
          //   this.setState({ unMessageNum: unread });
          // }}
          systemType={SystemType.BOSS_PC}
          userDimension
        />

        {/* <span */}
        {/*   className={styles.userInfoLeft} */}
        {/*   onClick={() => window.top.egenie.openTab('/egenie-boss/noticeSetting', 'noticeSetting', '通知设置')} */}
        {/* > */}
        {/*   <span className={styles.blueText}> */}
        {/*     通知设置 */}
        {/*   </span> */}
        {/*   <span className={styles.splitIcon}> */}
        {/*     | */}
        {/*   </span> */}
        {/* </span> */}
      </div>
    );
  };

  public userInfoRight = (): RightOperation[] => {
    return [
      {
        id: 'changePhone',
        name: '修改手机号',
        callback: () => {
          this.toggleChangePhone(true);
        },
      },
      {
        id: 'export',
        name: '导入导出任务中心',
        callback: () => top.egenie.openTab('/egenie-ts-baseinfo/exportList/index', 'export_task_center', '导入导出任务中心', 'zc_pfs'),
      },
    ];
  };

  render(): JSX.Element {
    return (
      <div>
        <LayoutGuide
          defaultDashboard={<BossDashBoard pushAllUnreadNum={this.state.unMessageNum}/>}
          haveDashboard={false}
          store={layoutStore}
          userInfoLeft={this.userInfoLeft()}
          userInfoRight={this.userInfoRight()}
        />
        {this.state.isShowPhoneChange && this.changePhoneModal()}
      </div>
    );
  }
}
