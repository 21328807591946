import { Empty } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import { api } from '../../../../../utils';
import { PAGE_TYPE } from '../../constant';
import { Header } from '../base/base';
import styles from '../index.less';
import NotificationDrawer from '../../../../notificationDrawer';
import NotificationDrawerStore from '../../../../notificationDrawer/store';
import { SystemType } from '../../../../components/noticeBadge/constant';

const notificationCenterStore = new NotificationDrawerStore({ parent: this });

const goToNoticeCenter = () => {
  // window.top.egenie.openTab('/egenie-erp-home/noticeCenter', 'noticeCenter', '通知中心');
  notificationCenterStore.show();
};

const goToNoticeSetting = () => {
  window.top.egenie.openTab('/egenie-boss/noticeSetting', 'noticeSetting', '通知设置');
};

export const NoticeCenter = observer((props) => {
  const {
    messageList,
    allUnreadNum,
    settingExist,
    hasRequest,
  } = props.store;
  const { pushAllUnreadNum } = props;

  return (
    <div className={styles.noticeCenterWrap}>
      <Header
        btnRight={<GotoCheckNotice unreadCount={pushAllUnreadNum || allUnreadNum}/>}
        title="通知中心"
      />
      <div className={styles.noticeList}>
        {/* {!settingExist && ( */}
        {/*   <div */}
        {/*     className={styles.noContentWrap} */}
        {/*     onClick={goToNoticeSetting} */}
        {/*   > */}
        {/*     <NoSetTip/> */}
        {/*   </div> */}
        {/* )} */}
        {hasRequest && messageList.length === 0 && (
          <div className={styles.empty}>
            <Empty
              description="暂无数据哦！"
              image={`${api.oss}/images/empty.png`}
            />
          </div>
        )}
        {settingExist && hasRequest && messageList.length > 0 && messageList?.slice(0, 3)
          ?.map((item) => {
            return (
              <div
                className={styles.messageItem}
                key={item.id}
              >
                <div className={styles.messageHeaderWrap}>
                  <span className={styles.messageHeader}>
                    {item.title}
                  </span>
                  <span className={styles.createTime}>
                    {item.createTimeStr}
                  </span>
                </div>
                <div className={styles.messageInfo}>
                  {item.messageContents?.[0]?.value}
                </div>
                <div
                  className={styles.checkBtn}
                  onClick={() => {
                    window.top.egenie.openTab(`${PAGE_TYPE[item.pageType]?.url}?messageType=${item.messageType}`, `${PAGE_TYPE[item.pageType]?.pageId}`, `${PAGE_TYPE[item.pageType]?.name}`);
                  }}
                >
                  <span>
                    查看
                  </span>
                  <i className="icon-sq"/>
                </div>
              </div>
            );
          })}
      </div>
      <NotificationDrawer
        store={notificationCenterStore}
        systemType={SystemType.BOSS_PC}
        userDimension
      />
    </div>
  );
});

const GotoCheckNotice = observer((props) => {
  const { unreadCount } = props;
  return (
    <div
      className={`${styles.unreadWrap} ${!unreadCount ? styles.noUnread : ''}`}
      onClick={goToNoticeCenter}
    >
      {unreadCount > 0 ? (
        <span>
          {unreadCount > 99 ? '99+' : unreadCount}
          未读
        </span>
      ) : (
        <span className={styles.more}>
          更多
        </span>
      )}
      <i
        className="icon-sq"
        style={{ color: '#2b2e3e' }}
      />
    </div>
  );
});
