import { observer } from 'mobx-react';
import React from 'react';
import { ORDER_LIST, SALES_ORDER_LIST } from '../constant';
import { Header, OrderPanel } from './base/base';
import styles from './index.less';

export const handleData = (arr, data) => {
  return arr.map((item) => {
    item.count = data?.[item.value] || 0;
    return item;
  });
};
export const OrderAgent = observer((props) => {
  const { orderStaticInfo, saleOrderStaticInfo } = props.store;
  const orderStatic = handleData(ORDER_LIST, orderStaticInfo);
  const saleOrderStatic = handleData(SALES_ORDER_LIST, saleOrderStaticInfo);
  return (
    <div className={styles.orderAgentWrap}>
      <Header title="订单待办"/>
      <OrderPanel
        background="linear-gradient(270deg, #FFFFFF 0%, #9AB3FF 100%)"
        countColor="#1978FF"
        orderStaticInfo={orderStaticInfo}
        panels={orderStatic}
        style={{ marginBottom: '5%' }}
        title="选款采购订单"
      />
      <OrderPanel
        background="linear-gradient(270deg, #FFFFFF 0%, #FFC49F 100%)"
        countColor="#FD7D47"
        orderStaticInfo={saleOrderStaticInfo}
        panels={saleOrderStatic}
        title="选款分销订单"
      />
    </div>
  );
});
